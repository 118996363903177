import { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";

export type Props = {
  type: StatusTypeEnum;
  message: string;
};

export enum StatusTypeEnum {
  INFO = "info",
  ERROR = "error",
  SUCCESS = "success",
  NONE = "",
}

const Status: React.FC<Props> = ({ type = "", message = "" }) => {
  useEffect(() => {
    if (type == StatusTypeEnum.INFO) {
      toast.info(message, {
        position: "top-center",
        containerId: "status",
        toastId: message,
      });
    } else if (type == StatusTypeEnum.ERROR) {
      toast.error(message, {
        position: "top-center",
        containerId: "status",
        toastId: message,
      });
    } else if (type == StatusTypeEnum.SUCCESS) {
      toast.success(message, {
        position: "top-center",
        containerId: "status",
        toastId: message,
      });
    }
  }, [type, message]);

  return (
    <>
      <ToastContainer containerId="status" position="top-center" />
    </>
  );
};

export default Status;
