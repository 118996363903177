import { useRouter } from "next/router";
import { useEffect } from "react";
import { routeInfo } from "helpers/routerUtils";

/**
 * AppRouterCounter adds support for navigating forward and backwards in
 * the app-state. See 'page.memory.js'
 */
export const AppRouterCounter = ({ children }) => {
  const router = useRouter();
  useEffect(() => {
    const onRouteChangeComplete = url => {
      routeInfo.counter++;
    };
    router.events.on("routeChangeComplete", onRouteChangeComplete);
    return () => {
      router.events.off("routeChangeComplete", onRouteChangeComplete);
    };
  }, [router.events]);
  return children;
};
