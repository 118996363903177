import { useRouter } from "next/router";
import { useSession } from "next-auth/react";
import jwtDecode from "jwt-decode";
import { TokenParsedRenUser } from "./RenUserTokenParsed";
import { createRenUser, createRenUserFromToken, RENUser } from "./ren-auth";

export function useRENAuth(): {
  renUser: RENUser;
  isProtectedRoute: boolean;
} {
  const router = useRouter();
  const isProtectedRoute = router.pathname.startsWith("/innlogget");
  const { status, data } = useSession();

  if (["loading", "unauthenticated"].includes(status) || !data) {
    return {
      isProtectedRoute,
      // Creates unauthenticated user with no roles.
      renUser: createRenUser(),
    };
  }

  try {
    const tokenParsed = jwtDecode(data.accessToken) as TokenParsedRenUser;
    const renUser = createRenUserFromToken(tokenParsed);
    return { renUser, isProtectedRoute };
  } catch (err) {
    console.log("Failed to build renUser from keycloak token", err);
    return {
      isProtectedRoute,
      renUser: createRenUser(),
    };
  }
}
