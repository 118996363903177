import React, { useContext, useEffect, useMemo, useState } from "react";

const initialState = {
  isDebugMode: false,
  setIsDebugMode(val: boolean) {
    return;
  },
};

const DebuggerContext = React.createContext(initialState);

const storageKey = "debug-mode";

interface Props {
  children: React.ReactNode;
}

export const DebugModeProvider: React.FC<Props> = ({ children }) => {
  const [isDebugMode, setIsDebugMode] = useState(false);

  useEffect(() => {
    if (typeof window === "undefined") {
      return; // do nothing
    }
    // Any value is interpreted as truthy
    const storedVal = localStorage.getItem(storageKey) === "true";
    setIsDebugMode(storedVal);
  }, []);

  return (
    <DebuggerContext.Provider
      value={{
        isDebugMode,
        setIsDebugMode: newVal => {
          if (newVal) {
            localStorage.setItem(storageKey, "true");
            setIsDebugMode(true);
            return;
          }
          localStorage.removeItem(storageKey);
          setIsDebugMode(false);
        },
      }}
    >
      {children}
    </DebuggerContext.Provider>
  );
};

export const useDebugMode = () => {
  return useContext(DebuggerContext);
};
