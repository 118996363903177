import LoadingSpinner from "components/shared/loadingSpinner";
import { signIn, useSession } from "next-auth/react";
import { useEffect } from "react";

// Inspired by: https://next-auth.js.org/getting-started/client#custom-client-session-handling

export function AppAuthRequireLogin({ children }) {
  const { status, data } = useSession();

  useEffect(() => {
    if (status === "unauthenticated") {
      void signIn("keycloak"); // Force sign in to hopefully resolve error
    }

    if (data?.error === "RefreshAccessTokenError") {
      void signIn("keycloak"); // Force sign in to hopefully resolve error
    }
  }, [data, status]);

  // TODO: Consider if this is disturbs the user somehow.
  if (status !== "authenticated") {
    return <LoadingSpinner />;
  }

  return children;
}
