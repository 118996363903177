import React, { useContext } from "react";
import { StatusTypeEnum } from "./shared/status";

export type NotificationOptions = {
  // Specify type of popup to show.
  type: StatusTypeEnum[keyof StatusTypeEnum];
  // Specify the popup's message.
  message: string;
  // Additional data to be bundled with the Sentry report.
  extraSentryInfo?: Record<string, string>;
};

export type ThrowerFunc = (
  // First argument is plain error object. Useful
  // to keep it as it's own argument when this
  // function is given to .catch(throwError) clauses.
  error: Error,
  options?: NotificationOptions
) => void;

// Does nothing. We switch this out with another function when using the ErrorContext.
// See ErrorBoundary.
const defaultThrowError = (error: Error) => undefined;

export const ErrorContext = React.createContext<ThrowerFunc>(defaultThrowError);

// Let sub-components throw errors to be eventually caught by ErrorBoundary
export const useErrorThrower = () => {
  return useContext(ErrorContext);
};
