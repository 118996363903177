/**
 * NOTE: The `Next/font/google`-package downloads, optimizes and __serves__
 *       the font from ren.no/_next/static/media, this is a GDPR-compliant
 *       and privacy-friendly way to use Google fonts.
 * docs: https://nextjs.org/docs/app/building-your-application/optimizing/fonts
 */
import { Roboto_Flex, Roboto_Mono } from "next/font/google";
const robotoFlex = Roboto_Flex({
  subsets: ["latin"],
  variable: "--font-family-sans", // Override variable declared in @ren-no/design-tokens
  display: "swap",
});
const robotoMono = Roboto_Mono({
  subsets: ["latin"],
  variable: "--font-family-mono", // Override variable declared in @ren-no/design-tokens
  display: "swap",
});

export function FontWrapper({ children }: { children: React.ReactNode }) {
  return (
    <div className={`${robotoFlex.variable} ${robotoMono.variable}`}>
      {children}
    </div>
  );
}
