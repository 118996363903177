import React from "react";

interface Props {
  children: React.ReactNode;
}

export const ShowOnLocalhost: React.FC<Props> = ({ children }) => {
  if (
    typeof window === "undefined" ||
    window.location.hostname !== "localhost"
  ) {
    return null;
  }
  return <>{children}</>;
};
