import dynamic from "next/dynamic";
import { AppAuthRequireLogin } from "./app-auth-require-login";
import { AppAuthSentry } from "./app-auth-sentry";
import { useEffect } from "react";
import { signIn, signOut, useSession } from "next-auth/react";
import { useRENAuth } from "../../helpers/security/ren-auth-client-side";

const Browser = dynamic(() => import("helpers/browserUpdate"), {
  ssr: false,
});

// Purpose: Only load dynamic RequireComponent if we're
// under '/innlogget'
export function AppAuth({ children }) {
  const { isProtectedRoute } = useRENAuth();
  const { data } = useSession();

  useEffect(() => {
    // If the /api/next-auth encounters trouble with
    // using the refresh token we assume that the user
    // has logged out.
    if (data?.error === "RefreshAccessTokenError" && isProtectedRoute) {
      // Trigger re-login if user is trying to view
      // protected route. We delete the useless next-auth session
      // first because its related refresh_token fails.
      void signOut({ redirect: false }).then(() => signIn("keycloak"));
    } else if (data?.error === "RefreshAccessTokenError") {
      // If user's refresh_token has timed out, and they're
      // just viewing unprotected pages, we delete
      // the next-auth session. UI should update to reflect
      // this.
      void signOut({ redirect: false });
    }
  }, [data?.error, isProtectedRoute]);

  if (isProtectedRoute) {
    return (
      <AppAuthRequireLogin>
        <AppAuthSentry>{children}</AppAuthSentry>
        <Browser />
      </AppAuthRequireLogin>
    );
  }
  return children;
}
